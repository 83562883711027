import {
  isCompanyPage,
  isInternalPage,
  isTargetAttributeValid
} from '~ui/helpers/linkUtils'

/**
 * Formats anchor tags in the text according to specific rules based on the base URL,
 * removing previous 'rel' attributes if 'target' is present, while preserving other attributes.
 * @param {string} text - source text to process
 * @param {string} baseUrl - base URL of the site to identify internal links
 * @returns source text with anchor tags formatted
 */
const formatLinks = (text: string, baseUrl?: string) => {
  if (!baseUrl) return text
  return text.replace(
    /<a\s+([^>]*?)href="([^"]+)"(.*?)>/gi,
    (_, preHref, rawHref, postHref) => {
      const isInternal = isInternalPage(rawHref, baseUrl)

      const href = isInternal ? rawHref.replace(baseUrl, '') : rawHref

      const blankTargetAttrs =
        isInternal || isCompanyPage(href)
          ? 'target="_blank" rel="noopener"'
          : 'target="_blank" rel="noopener noreferrer nofollow"'

      let attrs = preHref + postHref

      const targetAttrValue = attrs.match(/target="([^"]*)"/)?.[1]

      if (
        targetAttrValue === '_blank' ||
        (!targetAttrValue && !isInternal) ||
        !isTargetAttributeValid(targetAttrValue)
      ) {
        attrs = `${blankTargetAttrs} ${attrs.replace(/\s*(target="[^"]*"|rel="[^"]*")/gi, '').trim()}`
      }

      return `<a href="${href}" ${attrs}`.replace(/\s{2,}/g, ' ').trim() + '>'
    }
  )
}

/**
 * Adds hard space after conjunctions and other language specific words
 * to move them to the next line according to spelling rules
 * @param {string} text - source text to process
 * @param {string} lang - language code in ISO 639-1 (e.g. en, pl)
 * @returns source text with hard spaces added or source text if no languages rules was found
 */
const addHardSpace = (text: string, lang?: string) => {
  const regularExpressionList: { [key: string]: RegExp } = {
    pl: /\s+(i|oraz|lub|albo|bądź|czy|ani|ni|aż|że|o|ta|to|te|tę|tą|do|od|na|by|w|we|bo|jak|a|z|niż|lub|&amp;)\s+/gim,
    en: /\s+(a|an|of|the|at|in|by|and|or|to|for|as|is|are|so|on|&amp;)\s+/gim,
    de: /\s+(zu|von|und|zur|der|die|das|ist|es|für|an|&amp;)\s+/gims
  }

  if (!(lang && lang in regularExpressionList)) return text
  return text.replace(regularExpressionList[lang], ' $1&nbsp;')
}

/**
 * Wraps specific words into `<span class="word-no-wrap">` to prevent word break
 * Currently wraps words: `e-commerce`, `B2B-E-Commerce`, `B2C-E-Commerce` (case insensitive)
 * @param {string} text - source text to process
 * @returns source text with spans added
 */
const noWrapWords = (text: string) => {
  try {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp(
      '(?<!=[\'"][^\'"]*)(\\be-commerce\\b|\\bB2B-E-Commerce\\b|\\bB2C-E-Commerce\\b)',
      'gi'
    )
    return text.replace(regex, '<span class="word-no-wrap">$1</span>')
  } catch (err) {
    console.warn(`Not supported regular expression skipped! ${err}`)
    return text
  }
}

/**
 * Applies text formatting functions to ensure line breaking where needed and prevent break specific words
 * For detailed text transforms description see `noWrapWords()` and `addHardSpace()` specs
 * @param {string} text - source text to process
 * @param {string} lang - language code in ISO 639-1 (e.g. en, pl)
 * @param {string} baseUrl - base URL of the site for determining relative links
 * @returns processed source text with added hard spaces and word non-breakable spans
 */
const niceTextFormat = (
  text: string,
  lang: string | undefined,
  baseUrl: string | undefined
) => {
  if (!text) return ''

  let result = text
  result = formatLinks(text, baseUrl)
  result = addHardSpace(result, lang)
  result = noWrapWords(result)
  return result
}

export { niceTextFormat }
