<template>
  <T3HtmlParser ref="htmlparser" class="t3-html-parser" />
</template>

<script setup lang="ts">
import { computed, h, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

import { navigateTo } from '#app'
import { useCurrentLocale, useT3Options } from '#imports'

import type { T3HtmlParserProps } from '~/types'
import { niceTextFormat } from '~ui/helpers/niceTextFormat'

const props = withDefaults(defineProps<T3HtmlParserProps>(), {
  tag: 'div'
})

const currentLocale = useCurrentLocale()
const { options } = useT3Options()

const preparedContent = computed(() =>
  niceTextFormat(props.content, currentLocale.value, options.baseUrl)
)

const T3HtmlParser = () => h(props.tag, { innerHTML: preparedContent.value })

onMounted(() => {
  nextTick(addListeners)
})

onBeforeUnmount(() => {
  removeListeners()
})

const htmlparser = ref<HTMLElement | null>(null)
const links = ref<Array<HTMLAnchorElement>>([])

function addListeners() {
  if (!htmlparser.value) return

  links.value = Array.from(htmlparser.value.getElementsByTagName('a'))

  if (links.value.length) {
    for (let i = 0; i < links.value.length; i++) {
      links.value[i].addEventListener('click', navigate, false)
    }
  }
}

function removeListeners() {
  if (links.value.length) {
    for (let i = 0; i < links.value.length; i++) {
      links.value[i].removeEventListener('click', navigate, false)
    }

    links.value = []
  }
}

function navigate(e: MouseEvent) {
  const target = e.currentTarget
  if (target instanceof HTMLAnchorElement) {
    return redirect(e, target)
  }
}

function redirect(e: MouseEvent, target: HTMLAnchorElement) {
  const href = target.getAttribute('href')
  const hrefTarget = target.getAttribute('target')
  const isCtrlKeyPressed = e.ctrlKey || e.metaKey

  const openInNewTab =
    (hrefTarget && hrefTarget === '_blank') || isCtrlKeyPressed

  if (href && href[0] === '/' && !openInNewTab) {
    e.preventDefault()
    navigateTo(href)
  }
}
</script>
